import { NextPage } from 'next'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Button } from '@ucheba/ui/components/Button/touch'
import React, { useMemo } from 'react'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Navigation } from '@ucheba/ui/components/Navigation/touch'
import { Container } from '@ucheba/ui/components/Container/touch'
import { sectionsEventsNavigationItems } from '../../for-abiturients/events/index/constants'
import { useIndexPageEventsHeaderCore } from '../bll'
import { EventsList } from '../../../components/EventsList/touch'
import { eventsSearchPath } from '../constants'
import SC from '../base/IndexPageCalendar.styles'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  margin: ${sv.spacing.uxlarge} 0;
`

const IndexPageEvents: NextPage = () => {
  const allEventsHref = useMemo(() => `${eventsSearchPath}`, [])

  const { title, activeIndex, onClick, events } = useIndexPageEventsHeaderCore()

  if (!events) return null

  return (
    <Block>
      <Container>
        <Spacing spacing='xlarge' />

        <Heading h2={title} spacing='small' />

        <Spacing spacing='small' />

        <Navigation
          items={sectionsEventsNavigationItems}
          activeIndex={activeIndex}
          onClick={onClick}
        />

        <Spacing spacing='large' />

        {events.length ? <EventsList items={events} href={allEventsHref} /> : null}

        <Spacing spacing='xlarge' />

        <Button block view='outline' color='blue' href={allEventsHref} spa>
          Показать все мероприятия
        </Button>
      </Container>
    </Block>
  )
}

export default IndexPageEvents
