import React, { useCallback } from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/touch'
import styled from 'styled-components'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import Link from 'next/link'
import { Button } from '@ucheba/ui/components/Button/touch'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { List, ListItem } from '@ucheba/ui/components/List/touch'
import { Dropdown } from '@ucheba/ui/components/Dropdown/touch'
import { Menu, MenuItem } from '@ucheba/ui/components/Menu/touch'
import { IInstitutionsRankingsStatsItem } from '@ucheba/store/institutions/rankings/stats/types'
import { getCountForm } from '@ucheba/utils/helpers/number'
import { Picture } from '@ucheba/ui/components/Picture'
import { IIndexPageProps } from '../types'
import SC from '../base/IndexPageRatings.styles'
import { useInstitutionsRankingsCore } from '../bll'

const { Block: BlockSC, StyledButton, DropdownItem } = SC

const Block = styled(BlockSC)`
  margin: ${sv.spacing.uxlarge} 0;
`

const Image = styled(Picture)`
  height: 20px;
`

const IndexPageRatings: NextPage<IIndexPageProps['content']['ratings']> = (props) => {
  const { title } = props

  const {
    isOpenDropdownMenu,
    visibleRankingsList: list,
    openDropdownMenu,
    getRankingHref,
    getSubRankingHref,
  } = useInstitutionsRankingsCore()

  const dropdownMenu = useCallback(
    (ratingItem: IInstitutionsRankingsStatsItem): JSX.Element => {
      return (
        <Menu>
          {ratingItem?.children.dropdownSubRatings.map((dropdownItem) => (
            <MenuItem key={dropdownItem.id}>
              <Link passHref href={getSubRankingHref(dropdownItem.id, ratingItem.id)}>
                <DropdownItem
                  as={Button}
                  color='blue'
                  view='link'
                  onClick={openDropdownMenu(ratingItem.id)}
                >
                  <Text html={false}>
                    {dropdownItem.name}{' '}
                    <Text as='span' color='blue70'>
                      {dropdownItem.institutionCount}
                    </Text>
                  </Text>
                </DropdownItem>
              </Link>
            </MenuItem>
          ))}
        </Menu>
      )
    },
    [getSubRankingHref, openDropdownMenu]
  )

  return (
    <Block>
      <Container>
        <Heading h2={title} spacing='large' />

        {list.map((ratingItem, ratingIndex) => (
          <React.Fragment key={ratingIndex}>
            <Image
              alt='логотип организации'
              fallbackUrl={ratingItem?.logo?.fallbackUrl}
              webpUrl={ratingItem?.logo?.webp}
            />

            <Spacing spacing='xsmall' />

            <Text as='h5' html={false} fontSize='h5' fontWeight='semibold'>
              {ratingItem.name}{' '}
              <Text as='span' color='gray50'>
                {ratingItem.year}
              </Text>
            </Text>

            <Spacing spacing='xxsmall' />

            <List spacing='xxsmall'>
              <ListItem>
                <Link passHref href={getRankingHref(ratingItem.id)}>
                  <StyledButton as={Button} size='small' color='blue' view='link'>
                    {`${ratingItem.institutionCount} ${getCountForm(
                      ratingItem.institutionCount,
                      ['вуз', 'вуза', 'вузов']
                    )}`}
                  </StyledButton>
                </Link>
              </ListItem>

              {ratingItem?.children.visibleSubRatings?.map((subRating) => (
                <ListItem key={subRating.id}>
                  <Link passHref href={getSubRankingHref(subRating.id, ratingItem.id)}>
                    <StyledButton as={Button} size='xsmall' color='blue' view='link'>
                      <Spacing spacing='xxsmall' />

                      <Text textAlign='left' fontWeight='regular' html={false}>
                        {subRating.name}{' '}
                        <Text as='span' color='blue70'>
                          {`${subRating.institutionCount}&nbsp;${getCountForm(
                            subRating.institutionCount,
                            ['вуз', 'вуза', 'вузов']
                          )}`}
                        </Text>
                      </Text>
                    </StyledButton>
                  </Link>
                </ListItem>
              ))}

              {ratingItem?.children.dropdownSubRatings && (
                <ListItem>
                  <Dropdown
                    open={isOpenDropdownMenu(ratingItem.id)}
                    color='blue'
                    title='Все направления'
                    menu={dropdownMenu(ratingItem)}
                  />
                </ListItem>
              )}
            </List>

            <Spacing spacing='large' />
          </React.Fragment>
        ))}
      </Container>
    </Block>
  )
}

export default IndexPageRatings
