import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/touch'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import Image from 'next/image'
import { Description } from '@ucheba/ui/components/Description/touch'
import Link from 'next/link'
import { IIndexPageProps } from '../types'
import SC from '../base/IndexPageFooter.styles'
import boyImage from '../assets/img__footer-boy.png'
import computerImage from '../assets/img__footer-computer.png'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  padding: ${sv.spacing.uxlarge} 0;
`

const IndexPageFooter: NextPage<IIndexPageProps['content']['footer']> = (props) => {
  const { title, href, buttonText, twoMillionOfStudents, subtitle, CRMSystem } = props

  return (
    <Block>
      <Container>
        <Link href={href} passHref>
          <Text fontWeight='bold' color='white'>
            {subtitle}
          </Text>

          <Spacing spacing='xsmall' />

          <Heading h1={title} spacing='medium' color='white' />

          <Button color='gold'>{buttonText}</Button>
        </Link>

        <Spacing spacing='xlarge' />

        <Image src={boyImage} width={83} height={80} alt='' />

        <Spacing spacing='large' />

        <Heading h3={twoMillionOfStudents.title} spacing='xxsmall' color='yellow' />

        <Description description={twoMillionOfStudents.description} color='white' />

        <Spacing spacing='xlarge' />

        <Image src={computerImage} width={91} height={80} alt='' />

        <Spacing spacing='large' />

        <Heading h3={CRMSystem.title} spacing='xxsmall' color='yellow' />

        <Description description={CRMSystem.description} color='white' />
      </Container>
    </Block>
  )
}

export default IndexPageFooter
