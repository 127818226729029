import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/touch'
import { Box } from '@ucheba/ui/components/Box/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Description } from '@ucheba/ui/components/Description/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import Image from 'next/image'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import Link from 'next/link'
import SC from '../base/IndexPageServices.styles'
import { IIndexPageProps } from '../types'
import bannerEgeImage from '../assets/img__services-ege_touch.png'
import bannerProfessionImage from '../assets/img__services-profession_touch.png'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  margin-bottom: ${sv.spacing.uxlarge};
`

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

const IndexPageServices: NextPage<IIndexPageProps['content']['services']> = (props) => {
  const { ege, profession } = props

  return (
    <Block>
      <Container>
        <Link href={ege.href} passHref>
          <StyledBox as={Box} color='gold' spacing='null'>
            <Box spacing='small'>
              <Heading h2={ege.title} spacing='xxsmall' />

              <Description description={ege.description} />

              <Spacing spacing='small' />

              <Button block color='orange'>
                {ege.buttonText}
              </Button>
            </Box>

            <Spacing spacing='small' />

            <Image src={bannerEgeImage} width={288} height={144} layout='responsive' />
          </StyledBox>
        </Link>

        <Spacing spacing='small' />

        <Link href={profession.href} passHref>
          <StyledBox as={Box} color='blue' spacing='null'>
            <Box spacing='small'>
              <Heading h2={profession.title} spacing='xxsmall' />

              <Description description={profession.description} />

              <Spacing spacing='small' />

              <Button block color='gold'>
                {profession.buttonText}
              </Button>
            </Box>

            <Spacing spacing='small' />

            <Image
              src={bannerProfessionImage}
              width={288}
              height={144}
              layout='responsive'
            />
          </StyledBox>
        </Link>
      </Container>
    </Block>
  )
}

export default IndexPageServices
