import React from 'react'
import { NextPage } from 'next'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import Image from 'next/image'
import { IIndexPageProps } from '../types'
import SC from '../base/IndexPageLogotypes.styles'

const { Block: BlockSC, LogotypesList: LogotypesListSC } = SC

const Block = styled(BlockSC)`
  padding: ${sv.spacing.xlarge} 0;
  margin-top: ${sv.spacing.small};
`

const LogotypesList = styled(LogotypesListSC)`
  padding-left: ${sv.spacing.small};
  display: flex;
  width: 100%;
  flex-wrap: no-wrap;
  overflow-x: auto;

  & img {
    margin-right: ${sv.spacing.large};
  }
`

const ImgWrap = styled.div`
  margin-right: ${sv.spacing.large};
  min-width: 64px;
  width: auto;
  display: flex;
  align-items: center;
`

const IndexPageLogotypes: NextPage<IIndexPageProps['content']['institutionLogotypes']> = (
  props
) => {
  const { list } = props

  return (
    <Block>
      <LogotypesList>
        {list.map(({ href, width, height, logo }, index) => (
          <ImgWrap
            key={index}
            style={{
              minWidth: `${width}px`,
            }}
          >
            <a href={href}>
              <Image src={logo} width={width} height={height} />
            </a>
          </ImgWrap>
        ))}
      </LogotypesList>
    </Block>
  )
}

export default IndexPageLogotypes
