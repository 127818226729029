import React, { memo, useState } from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/touch'
import { useBottomSheetHeight } from '@ucheba/ui/components/Select/bll'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/touch'
import { Banner } from '../../../components/Banner/touch'
import { IIndexPageProps } from '../types'
import IndexPageHero from './IndexPageHero'
import IndexPageLogotypes from './IndexPageLogotypes'
import IndexPageServices from './IndexPageServices'
import IndexPageSpecialties from './IndexPageSpecialties'
import IndexPageRatings from './IndexPageRatings'
import IndexPageFooter from './IndexPageFooter'
import IndexPageChooseInstitutionType from './IndexPageChooseInstitutionType'
import { useIndexPageCore } from '../bll'
import IndexPageEvents from './indexPageEvents'
import IndexPageArticles from './IndexPageArticles'
import IndexPageAboutTeachers from './IndexPageAboutTeachers'

/** Главная страница */
const IndexPage: NextPage<IIndexPageProps> = (props) => {
  const { content } = props
  const {
    hero,
    institutionLogotypes,
    services,
    specialties,
    ratings,
    footer,
    chooseInstitutionTypeDialogContent,
  } = content

  const [isOpenChooseInstitutionType, toggleOpenChooseInstitutionType] = useState(false)

  const chooseInstitutionType = useBottomSheetHeight({
    isOpen: isOpenChooseInstitutionType,
  })

  const { institutionType, setInstitutionType } = useIndexPageCore()

  return (
    <div>
      <IndexPageHero
        {...hero}
        isOpenChooseInstitutionType={isOpenChooseInstitutionType}
        toggleOpenChooseInstitutionType={toggleOpenChooseInstitutionType}
        institutionType={institutionType}
      />

      <IndexPageLogotypes {...institutionLogotypes} />

      <IndexPageServices {...services} />

      <IndexPageSpecialties {...specialties} institutionType={institutionType} />

      <IndexPageRatings {...ratings} />

      <IndexPageArticles />

      <Container>
        <Banner id={5} />
      </Container>

      <IndexPageEvents />

      <IndexPageAboutTeachers />

      <IndexPageFooter {...footer} />

      <BottomSheet
        open={isOpenChooseInstitutionType}
        toggleOpen={toggleOpenChooseInstitutionType}
        ref={chooseInstitutionType.bottomSheetRef}
        height={chooseInstitutionType.bottomSheetHeight}
      >
        <IndexPageChooseInstitutionType
          {...chooseInstitutionTypeDialogContent}
          setInstitutionType={setInstitutionType}
          closeDialog={toggleOpenChooseInstitutionType}
        />
      </BottomSheet>

      <Banner id={7} />
      <Banner id={14} />
    </div>
  )
}

export default memo(IndexPage)
