import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/touch'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import Link from 'next/link'
import { Text } from '@ucheba/ui/components/Text/touch'
import { useSelector } from 'react-redux'
import { articlesSelectors } from '@ucheba/store/articles'
import SC from '../base/IndexPageArticles.styles'
import { articlesTitle } from '../constants'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  margin: ${sv.spacing.uxlarge} 0;
`

const ButtonSC = styled(Button)`
  height: auto;
  padding: 0;
`

const IndexPageTips: NextPage = () => {
  const articlesEntity = useSelector(articlesSelectors.entity)

  if (!articlesEntity) return null

  const { items } = articlesEntity

  if (!items) return null

  return (
    <Block>
      <Container>
        <Heading h2={articlesTitle} spacing='large' />

        {items.map((el, index) => (
          <React.Fragment key={index}>
            <Link passHref href={`/article/${el.id}`}>
              <ButtonSC color='blue' view='link'>
                <Text html={false} textAlign='left'>
                  <Text lineHeight='h3' textAlign='left' fontSize='h3' fontWeight='bold'>
                    {el.title}
                  </Text>

                  {el?.announce && (
                    <>
                      <Spacing spacing='xxsmall' />

                      <Text fontWeight='regular' fontSize='large'>
                        {el.announce}
                      </Text>
                    </>
                  )}
                </Text>
              </ButtonSC>
            </Link>

            <Spacing spacing='large' />
          </React.Fragment>
        ))}
      </Container>
    </Block>
  )
}

export default IndexPageTips
