import React, { useEffect, useRef, useState } from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/touch'
import styled from 'styled-components'
import { Button } from '@ucheba/ui/components/Button/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { useOnlyClientRendering } from '@ucheba/utils/hooks/useOnlyClientRendering'
import Image from 'next/image'
import { EInstitutionTypeValue, IIndexPageProps, TInstitutionTypeValue } from '../types'
import SC from '../base/IndexPageHero.styles'
import { headerHeight } from '../../../components/Header/constants'
import touchImage from '../assets/img__hero-touch.png'
import { useChooseInstitutionsHref } from '../bll'
import {
  useChooseLocationBottomSheetCore,
  useLocationText,
} from '../../../components/ChooseLocation/bll'

const {
  Block: BlockSC,
  Link,
  LinkContent,
  InstitutionText: InstitutionTextSC,
  ProgramsText: ProgramsTextSC,
  StudentsText: StudentsTextSC,
} = SC

const Block = styled(BlockSC)`
  margin-top: -${headerHeight.touch}px;
  padding-top: 96px;
`

const ImageWrapper = styled.div`
  width: calc(100% + 32px);
  transform: translateX(-16px);
  min-height: 320px;

  ${sm.breakpoint.smAndDown`
    position: relative;
    display: flex;
    align-items: flex-end;
  `};
`

const InstitutionText = styled(InstitutionTextSC)`
  top: 16px;
  left: 55%;
  z-index: 1;
`

const StudentsText = styled(ProgramsTextSC)`
  left: 55%;
  top: 55%;
  z-index: 1;
`

const ProgramsText = styled(StudentsTextSC)`
  top: 16px;
  left: 16px;
  z-index: 1;
`

const GridColSC = styled(GridCol)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const GridColStyled = styled(GridCol)`
  padding-bottom: 0;
`

const IndexPage: NextPage<IIndexPageProps['content']['hero']> = (props) => {
  const {
    buttonText,
    title,
    institutions,
    programs,
    students,
    institutionType,
    toggleOpenChooseInstitutionType,
    isOpenChooseInstitutionType,
  } = props

  const { baseText } = useLocationText()

  const href = useChooseInstitutionsHref(institutionType.value as TInstitutionTypeValue)

  const isClient = useOnlyClientRendering()

  const { isOpenChooseLocation, toggleOpenChooseLocation } =
    useChooseLocationBottomSheetCore()

  const imageWrapRef = useRef<any>()
  const [size, seSize] = useState<Record<string, number>>({ height: 360, width: 360 })

  useEffect(() => {
    const width = imageWrapRef?.current?.clientWidth
    seSize({ height: width || 320, width: width || 320 })
  }, [imageWrapRef?.current?.clientWidth])

  return (
    <Block>
      <Container>
        <Grid>
          <GridColSC sm={12}>
            <Heading h2={title} color='white' spacing='null' />

            <Link>
              <Text
                as='span'
                fontWeight='extrabold'
                fontSize='h2'
                lineHeight='h2'
                color='white'
              >
                в
              </Text>

              <LinkContent
                onClick={() => {
                  toggleOpenChooseInstitutionType(!isOpenChooseInstitutionType)
                }}
              >
                <Text
                  as='span'
                  fontWeight='extrabold'
                  fontSize='h2'
                  lineHeight='h2'
                  color='gold'
                >
                  {`${institutionType?.visibleText}&nbsp;▾`}
                </Text>
              </LinkContent>
            </Link>

            <Link>
              <Text
                as='span'
                fontWeight='extrabold'
                fontSize='h2'
                lineHeight='h2'
                color='white'
              >
                в
              </Text>

              <LinkContent
                onClick={() => toggleOpenChooseLocation(!isOpenChooseLocation)}
              >
                <Text
                  as='span'
                  fontWeight='extrabold'
                  fontSize='h2'
                  lineHeight='h2'
                  color='flamingo'
                >
                  {`${baseText}&nbsp;▾`}
                </Text>
              </LinkContent>
            </Link>

            <Spacing spacing='small' />

            {isClient && (
              <Button block href={href} color='gold'>
                {institutionType.value === EInstitutionTypeValue.vuz
                  ? buttonText?.institution
                  : buttonText?.college}
              </Button>
            )}
          </GridColSC>

          <GridColStyled sm={12}>
            <ImageWrapper
              ref={imageWrapRef}
              style={{
                height: size.height,
              }}
            >
              <InstitutionText>
                <Text fontWeight='extrabold' color='white' fontSize='h3'>
                  {institutions.count}
                </Text>

                <Spacing spacing='usmall' />

                <Text uppercase fontSize='xsmall1' color='white' lineHeight='xsmall1'>
                  {institutions.text}
                </Text>
              </InstitutionText>

              <StudentsText>
                <Text fontWeight='extrabold' color='white' fontSize='h3'>
                  {students.count}
                </Text>

                <Spacing spacing='usmall' />

                <Text uppercase fontSize='xsmall1' color='white' lineHeight='xsmall1'>
                  {students.text}
                </Text>
              </StudentsText>

              <ProgramsText>
                <Text fontWeight='extrabold' color='white' fontSize='h3'>
                  {programs.count}
                </Text>

                <Spacing spacing='usmall' />

                <Text uppercase fontSize='xsmall1' color='white' lineHeight='xsmall1'>
                  {programs.text}
                </Text>
              </ProgramsText>

              <Image src={touchImage} width={size.width} height={size.height} />
            </ImageWrapper>
          </GridColStyled>
        </Grid>
      </Container>
    </Block>
  )
}

export default IndexPage
