import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/touch'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Navigation } from '@ucheba/ui/components/Navigation/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import Image from 'next/image'
import { Box } from '@ucheba/ui/components/Box/touch'
import { Description } from '@ucheba/ui/components/Description/touch'
import { EProgramTypes } from '@ucheba/utils/types/program'
import Link from 'next/link'
import { useOnlyClientRendering } from '@ucheba/utils/hooks/useOnlyClientRendering'
import SC from '../base/IndexPageSpecialties.styles'
import { IIndexPageProps } from '../types'
import { useSpecialtiesIndexPageCore } from '../bll'
import selectVuzByEgeTouchImage from '../assets/img__select-vuz-by-ege_touch.png'
import { sectionsNavigationItems } from '../../for-abiturients/speciality/index/constants'
import { SpecialityRubricCard } from '../../../components/SpecialityRubricCard/desktop'
import { forAbiturients } from '../../../routs'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  margin: ${sv.spacing.uxlarge} 0;
`

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
`

const IndexPageSpecialties: NextPage<IIndexPageProps['content']['specialties']> = (
  props
) => {
  const { title, promoBlock } = props

  const { activeIndex, onClick, activeCategory, items, getSpecialitiesHref } =
    useSpecialtiesIndexPageCore()

  // const href = useChooseInstitutionsHref(institutionType.value as TInstitutionTypeValue)

  const isClient = useOnlyClientRendering()

  return (
    <Block>
      <Container>
        <Heading h2={title} />

        <Navigation
          onClick={onClick}
          activeIndex={Number(activeIndex)}
          items={sectionsNavigationItems}
          color='black'
        />

        <Spacing spacing='large' />

        {activeCategory === String(EProgramTypes.higher) ? (
          <>
            {items?.map((specialtiesItem) => (
              <React.Fragment key={specialtiesItem.id}>
                <Link passHref href={getSpecialitiesHref(specialtiesItem.id)}>
                  <SpecialityRubricCard isInstitutions {...specialtiesItem} />
                </Link>

                <Spacing spacing='small' />
              </React.Fragment>
            ))}

            {isClient && (
              <Link passHref href={forAbiturients.ege.selectVuz.path}>
                <StyledBox as={Box} color='blue' spacing='null'>
                  <Box spacing='small'>
                    <Heading h2={promoBlock.title} spacing='xxsmall' />

                    <Description description={promoBlock.description} />

                    <Spacing spacing='small' />

                    <Button size='small' color='gold' block>
                      {promoBlock.buttonText}
                    </Button>
                  </Box>

                  <Spacing spacing='small' />

                  <Image
                    src={selectVuzByEgeTouchImage}
                    width={288}
                    height={144}
                    layout='responsive'
                    alt=''
                  />
                </StyledBox>
              </Link>
            )}
          </>
        ) : (
          items.map((specialtiesItem) => (
            <React.Fragment key={specialtiesItem.id}>
              <Link passHref href={getSpecialitiesHref(specialtiesItem.id)}>
                <SpecialityRubricCard
                  isInstitutions={false}
                  height='100%'
                  {...specialtiesItem}
                />
              </Link>

              <Spacing spacing='small' />
            </React.Fragment>
          ))
        )}
      </Container>
    </Block>
  )
}

export default IndexPageSpecialties
