import { FC } from 'react'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Container } from '@ucheba/ui/components/Container/touch'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import Image from 'next/image'
import Img1 from '../assets/IndexPageAboutTeachers/img__about-teachers__1.png'
import Img2 from '../assets/IndexPageAboutTeachers/img__about-teachers__2.png'
import Img3 from '../assets/IndexPageAboutTeachers/img__about-teachers__3.png'
import Img4 from '../assets/IndexPageAboutTeachers/img__about-teachers__4.png'

const Block = styled.section`
  background-color: ${sv.color.silver};
  padding: 64px 0;
`

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  margin: 0;
`
const WrapperItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`

const IndexPageAboutTeachers: FC = () => {
  return (
    <Block>
      <Container>
        <Heading h4='Репетиторам' spacing='xsmall' />

        <Heading
          h1='Новая удобная платформа для проведения уроков'
          as='h1'
          spacing='xsmall'
        />

        <Text fontSize='large' lineHeight='large'>
          Вместо зума, гугл-таблиц и форм, квизлетов, решуЕГЭ и кучи других разрозненных
          инструментов.
        </Text>

        <Spacing spacing='large' />

        <Wrapper>
          <WrapperItem>
            <Image src={Img1} alt='' width={48} height={48} />
            <Text html={false} fontSize='h4' fontWeight='bold' lineHeight='h4'>
              Планируйте и проводите
              <br /> онлайн-занятия
            </Text>
          </WrapperItem>

          <WrapperItem>
            <Image src={Img2} alt='' width={48} height={48} />
            <Text html={false} fontSize='h4' fontWeight='bold' lineHeight='h4'>
              Составляйте
              <br />и проверяйте работы
            </Text>
          </WrapperItem>

          <WrapperItem>
            <Image src={Img3} alt='' width={48} height={48} />
            <Text html={false} fontSize='h4' fontWeight='bold' lineHeight='h4'>
              Используйте банк
              <br />
              готовых заданий
            </Text>
          </WrapperItem>

          <WrapperItem>
            <Image src={Img4} alt='' width={48} height={48} />
            <Text html={false} fontSize='h4' fontWeight='bold' lineHeight='h4'>
              Контролируйте
              <br />
              процесс обучения
            </Text>
          </WrapperItem>
        </Wrapper>

        <Spacing spacing='large' />

        <Button
          block
          href='/for-teachers?utm_source=ucheba&utm_medium=mainpage&utm_campaign=repetitors_platform'
          color='blue'
        >
          Узнать больше
        </Button>
      </Container>
    </Block>
  )
}

export default IndexPageAboutTeachers
